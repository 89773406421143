import React from 'react';
import "./Page4.css"

export default function Page4({data}) {
  console.log("jojerofre",data?.personal)
  return (
    <div class="page-4 page-break-1">
    
    <div className='d-flex flex-column page-break-2' style={{justifyContent : "space-between"}}>
    <div
      className="head-managereport d-flex"
      style={{ justifyContent: "space-between" }}
    >
      <img src="/assets/img/logo.png" className="head-managereport-logo" />
      <div className="head-managereport-text1">Nutrition Management</div>
      <div
        className="text-flex-line-center-veritcal"
        style={{ color: "rgba(84, 84, 84, 1)" }}
      >
        Smart Report
      </div>
    </div>

    <div class="diet-sec">
      <div class="diet-heading">
         <h2 style={{fontSize : "22px",fontWeight : "600"}}>Importance of Diet in Hair loss and hair thinning</h2>
        <br />
      </div>
      <div class="diet-flex">
       <div class="diet-content">
         <div style={{fontSize : "14px"}}>
           A balanced diet is crucial for preventing hair loss and thinning.
           Key nutrients include vitamins, minerals, proteins, and healthy
           fats
         </div>
         <br/>
         
         <ul style={{listStyleType : "disc",paddingLeft: 0}}>
         <li> Protein for hair structure and strength</li>
         <li> Iron to promote growth and prevent shedding</li>
         <li> Biotin for thickness</li>
         <li> Omega-3 fatty acids for scalp health</li>
         <li> Vitamin C for iron absorption and collagen production</li>
         <li>
            Antioxidant-rich foods to protect follicles, hormonal balance
           &amp; hydration, reducing inflammation caused by poor diet
         </li>
         </ul>

         <div style={{fontSize : "14px"}}>
           Maintaining a well-balanced diet supports natural hair growth
           cycles, while crash diets can harm hair health.
         </div>
         <br />
       </div>
       <div class="div-img" style={{width : "40%"}}>
         <img src="/assets/img/reports/management/page4/importance of diet.png" alt="" class="diet-img" style={{height : "100%",width : "100%"}}/>
       </div>
      </div>
      <div class="diet-plan">
        <div class="dietplan-text">
          Maintaining a well-balanced diet supports natural hair growth
          cycles, while crash diets can harm hair health.
        </div>
      </div> 
    </div>
    <div style={{display : "flex",justifyContent : "center"}} className='mt-5'>
    <div class="faq-titledesign" style={{width : "80%"}}>
      <div class="faq-box1">
      </div>
      <div class="faq-box2">
        <div class="faq-boxdesign">
          <div class="faq-box3"></div>
          <div class="faq-box4"></div>
        </div>
        <span class="faq-title" style={{marginTop : "10px",fontSize : "20px"}}>
        Here is a diet plan focused on hair health, incorporating foods
          rich in essential nutrients for promoting hair growth &amp;
          preventing hair loss
        </span>
        <div class="faq-boxdesign">
          <div class="faq-box3"></div>
          <div class="faq-box4"></div>
        </div>
      </div>
      <div class="faq-box6">
      </div>
    </div>

    </div>

    <div
      className="head-managereport"
      style={{fontSize : "8px",height : "100%",color : "#FFFFFF",background: "rgba(0, 160, 227, 1)",marginTop : "12%"}}
    ><span style={{fontWeight:  "700"}}>Legal Disclaimer</span>: This communication aims to ensure your comprehensive understanding of the diagnosis's nature. (Read more.. Please be cognizant that, as of this juncture, the diagnosis is regarded as provisional, signifying its preliminary status. It is derived solely from the data and photos (if provided) obtained through the online hair test furnished by you. Nevertheless, it is imperative to accentuate that the precision of this diagnosis may exhibit incongruities across individuals. Various determinants, encompassing distinctive health conditions, genetic makeup, and external influences, can contribute to disparities in accuracy. While our utmost endeavor is directed towards accuracy, we hereby acknowledge the potential divergence in individual cases, thus warranting judicious discretion during the interpretation of outcomes.) Diagnosis & Details
    </div>
    </div>


    <div className='d-flex flex-column page-break-2' style={{justifyContent : "space-between"}}>
    <div
      className="head-managereport d-flex"
      style={{ justifyContent: "space-between" }}
    >
      <img src="/assets/img/logo.png" className="head-managereport-logo" />
      <div className="head-managereport-text1">Nutrition Management</div>
      <div
        className="text-flex-line-center-veritcal"
        style={{ color: "rgba(84, 84, 84, 1)" }}
      >
        Smart Report
      </div>
    </div>
    <div className='page-break-2'>

<div style={{display : "flex",justifyContent : "center"}} className='mt-5'>
  <div class="breakfast-titlebox" style={{    width: "40%",height : "100px"}}>
  <div class="breakfast-flextitle">
    <div class="breakfast-title" style={{    transform: "skew(-20deg)",width:"60%",paddingLeft: "20px",
fontWeight: "700"}}>
      Breakfast
    </div>
    <div style={{    width: "40%"}}>
    <img src="/assets/img/reports/management/page4/61964734-cbe4-4379-bd05-bac4d4d054f7.png" alt="" class="breakfast-img"
        style={{    width: "100%",height : "100%"}}
      />
    </div>
    
   </div>
  </div>

</div>
  <div class="breakfast-flex mt-5" style={{    padding: "14px",gap : "3%"}}>
    <div class="bordered-container teijsie ">
      <div class="labels">
        <img src="/assets/img/reports/management/page4/img.png" alt="image" class="label" />
        <img src="/assets/img/reports/management/page4/img2.png" alt="images" class="label" />
      </div>
      <h1 class="box-text" style={{margin : 0}}>Choose Any One</h1>
      <div class="breakfast-content mt-2">
        <div class="breakfast-fullcontent">
          <div class="management-report-page4-nutrition-mngmnt1-text135">
            1. Masala Omelette with Spinach and Tomatoes
          </div>
          
          <div class="management-report-page4-nutrition-mngmnt1-text137">
            Made with onions, tomatoes, and spinach (rich in biotin, iron,
            and vitamin C).
          </div>
          
          <div class="management-report-page4-nutrition-mngmnt1-text139">
            Serve with a whole-grain roti or multigrain toast (source of
            zinc and B-vitamins).
          </div>
          
          <div class="management-report-page4-nutrition-mngmnt1-text141">
            Add a side of fresh fruit like papaya or orange (contains
            antioxidants and vitamin C).
          </div>
          
          <div
            class="management-report-page4-nutrition-mngmnt1-text143"
          ></div>
          
          <div class="management-report-page4-nutrition-mngmnt1-text145">
            2. Oats Upma
          </div>
          
          <div class="management-report-page4-nutrition-mngmnt1-text147">
            Prepared with vegetables like carrots, peas, and beans.
          </div>
          
          <div class="management-report-page4-nutrition-mngmnt1-text149">
            Garnish with walnuts for added biotin, iron, and omega-3 fatty
            acids.
          </div>
          
          
          <div class="management-report-page4-nutrition-mngmnt1-text152">
            3. Smoothie with Spinach, Banana, and Chia Seeds
          </div>
          
          <div>
            Blend with almond milk or coconut milk (rich in iron, biotin,
            and omega-3 fatty acids).
          </div>
          
          <div>
            Pair with a small portion of poha or whole-grain toast for
            added nutrients.
          </div>
          
          <div></div>
          
        </div>
      </div>
    </div>

    <div class="bordered-container2 teijsie">
      <div class="labels">
        <img src="/assets/img/reports/management/page4/img.png" alt="image" class="label" />
        <img src="/assets/img/reports/management/page4/img2.png" alt="images" class="label" />
      </div>
      <h1 class="box-text" style={{margin : 0}}>Choose Any One</h1>
      <div class="breakfast-content  mt-2">
        <div class="breakfast-fullcontent2">
          <div class="management-report-page4-nutrition-mngmnt1-text162">
            1.Spinach-Banana Smoothie
          </div>
          
          <div class="management-report-page4-nutrition-mngmnt1-text164">
            Blend with chia seeds and almond milk (rich in iron, biotin,
            and omega-3 fatty acids).
          </div>
          
          <div class="management-report-page4-nutrition-mngmnt1-text166">
            Serve with whole-grain toast topped with avocado (provides
            healthy fats and B-vitamins).
          </div>
          
          <div
            class="management-report-page4-nutrition-mngmnt1-text168"
          ></div>
          
          <div class="management-report-page4-nutrition-mngmnt1-text170">
            2. Oats Porridge with Banana and Nuts
          </div>
          
          <div class="management-report-page4-nutrition-mngmnt1-text172">
            Cook with sliced bananas, walnuts, and a drizzle of honey
            (source of biotin, iron, and zinc).
          </div>
          
          <div
            class="management-report-page4-nutrition-mngmnt1-text174"
          ></div>
          
          <div class="management-report-page4-nutrition-mngmnt1-text176">
            3. Whole-Grain Toast with Almond Butter
          </div>
          
          <div>
            Top with sliced strawberries or seasonal fruit (contains
            biotin and vitamin C).
          </div>
          
          <div></div>
          
        </div>
      </div>
    </div>
  </div>



  </div>
  <div
      className="head-managereport"
      style={{fontSize : "8px",height : "100%",color : "#FFFFFF",background: "rgba(0, 160, 227, 1)"}}
    ><span style={{fontWeight:  "700"}}>Legal Disclaimer</span>: This communication aims to ensure your comprehensive understanding of the diagnosis's nature. (Read more.. Please be cognizant that, as of this juncture, the diagnosis is regarded as provisional, signifying its preliminary status. It is derived solely from the data and photos (if provided) obtained through the online hair test furnished by you. Nevertheless, it is imperative to accentuate that the precision of this diagnosis may exhibit incongruities across individuals. Various determinants, encompassing distinctive health conditions, genetic makeup, and external influences, can contribute to disparities in accuracy. While our utmost endeavor is directed towards accuracy, we hereby acknowledge the potential divergence in individual cases, thus warranting judicious discretion during the interpretation of outcomes.) Diagnosis & Details
    </div>

    
    </div>


    <div className='d-flex flex-column page-break-2' style={{justifyContent : "space-between"}}>
    <div
      className="head-managereport d-flex"
      style={{ justifyContent: "space-between" }}
    >
      <img src="/assets/img/logo.png" className="head-managereport-logo" />
      <div className="head-managereport-text1">Nutrition Management</div>
      <div
        className="text-flex-line-center-veritcal"
        style={{ color: "rgba(84, 84, 84, 1)" }}
      >
        Smart Report
      </div>
    </div>
      <div className='page-break-2'>
    <div style={{display : "flex",justifyContent : "center"}} className='mt-5'>
      <div class="breakfast-titlebox" style={{    width: "40%",    height: "92px"}}>
      <div class="breakfast-flextitle">
        <div class="breakfast-title" style={{    transform: "skew(-20deg)",width:"60%",paddingLeft: "20px",
    fontWeight: "700"}}>
          Lunch 
        </div>
        <div style={{    width: "40%"}}>
        <img src="/assets/img/reports/management/page4/23f0c079-7a00-4697-922c-cd9f8342b8d2.png" alt="" class="breakfast-img"
            style={{    width: "100%",height : "100%"}}
          />
        </div>
        
       </div>
      </div>

    </div>
      <div class="breakfast-flex mt-5" style={{    padding: "14px",gap : "3%"}}>
        <div class="bordered-container teijsie">
          <div class="labels">
            <img src="/assets/img/reports/management/page4/img.png" alt="image" class="label" />
            <img src="/assets/img/reports/management/page4/img2.png" alt="images" class="label" />
          </div>
          <h1 class="box-text" style={{margin : 0}}>Choose Any One</h1>
          <div class="breakfast-content mt-2">
            <div class="breakfast-fullcontent">
              <div class="management-report-page4-nutrition-mngmnt1-text185">
                1. Grilled Chicken or Paneer Salad
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text187">
                With mixed greens, bell peppers, cucumber, and avocado
                (provides protein, iron, and healthy fats).
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text189">
                Serve with quinoa, brown rice, or a small portion of bajra
                roti (source of protein and zinc).
              </div>
              
              
              <div class="management-report-page4-nutrition-mngmnt1-text192">
                2. Dal Soup with Mixed Vegetables
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text194">
                Use moong dal or masoor dal along with carrots, beans, and
                spinach (high in protein, iron, and zinc).
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text196">
                Serve with whole-grain roti or brown rice (rich in
                B-vitamins).
              </div>
              
              
              <div class="management-report-page4-nutrition-mngmnt1-text199">
                3. Chickpea and Vegetable Stir-Fry
              </div>
              
              <div>
                Toss chickpeas with bell peppers, carrots, and a splash of
                lemon juice (provides protein, vitamin C, and iron).
              </div>
              
              <div>
                Serve with brown rice, quinoa, or a small portion of jowar
                roti (source of zinc and B-vitamins).
              </div>
              
              
            </div>
          </div>
        </div>

        <div class="bordered-container teijsie">
          <div class="labels">
            <img src="/assets/img/reports/management/page4/img.png" alt="image" class="label" />
          </div>
          <h1 class="box-text" style={{margin : 0}}>Choose Any One</h1>
          <div class="breakfast-content mt-2">
            <div class="breakfast-fullcontent">
              <div class="management-report-page4-nutrition-mngmnt1-text208">
                1.Chana Salad with Fresh Vegetables
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text210">
                Combine boiled chickpeas, mixed greens, bell peppers,
                cucumber, and tomatoes (provides protein, iron, and
                antioxidants).
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text212">
                Serve with quinoa, brown rice, or a small portion of
                whole-wheat chapati (source of protein and zinc).
              </div>
              
              <div
                class="management-report-page4-nutrition-mngmnt1-text214"
              ></div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text216">
                2. Grilled Vegetable Wrap
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text218">
                Wrap grilled veggies like capsicum, zucchini, and onion with
                hummus in a whole-grain roti (contains protein, antioxidants,
                and dietary fiber).
              </div>
              
              <div
                class="management-report-page4-nutrition-mngmnt1-text220"
              ></div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text222">
                3. Rajma and Sweet Potato Chaat
              </div>
              
              <div>
                Mix boiled rajma with roasted sweet potato, avocado, and a
                tangy chutney (provides protein, iron, and healthy fats).
              </div>
              
              <div>
                Serve with a side of brown rice or a whole-wheat paratha
              </div>
              
              <div></div>
              
            </div>
          </div>
        </div>
      </div>
      </div>
      <div
      className="head-managereport"
      style={{fontSize : "8px",height : "100%",color : "#FFFFFF",background: "rgba(0, 160, 227, 1)"}}
    ><span style={{fontWeight:  "700"}}>Legal Disclaimer</span>: This communication aims to ensure your comprehensive understanding of the diagnosis's nature. (Read more.. Please be cognizant that, as of this juncture, the diagnosis is regarded as provisional, signifying its preliminary status. It is derived solely from the data and photos (if provided) obtained through the online hair test furnished by you. Nevertheless, it is imperative to accentuate that the precision of this diagnosis may exhibit incongruities across individuals. Various determinants, encompassing distinctive health conditions, genetic makeup, and external influences, can contribute to disparities in accuracy. While our utmost endeavor is directed towards accuracy, we hereby acknowledge the potential divergence in individual cases, thus warranting judicious discretion during the interpretation of outcomes.) Diagnosis & Details
    </div>

    </div>


    <div className='d-flex flex-column page-break-2' style={{justifyContent : "space-between"}}>
    <div
      className="head-managereport d-flex"
      style={{ justifyContent: "space-between" }}
    >
      <img src="/assets/img/logo.png" className="head-managereport-logo" />
      <div className="head-managereport-text1">Nutrition Management</div>
      <div
        className="text-flex-line-center-veritcal"
        style={{ color: "rgba(84, 84, 84, 1)" }}
      >
        Smart Report
      </div>
    </div>
      <div className='page-break-2'>
     <div style={{display : "flex",justifyContent : "center"}} className='mt-5'>
      <div class="breakfast-titlebox" style={{    width: "40%",    height: "92px"}}>
      <div class="breakfast-flextitle">
        <div class="breakfast-title" style={{    transform: "skew(-20deg)",width:"60%",paddingLeft: "20px",
    fontWeight: "700"}}>
          Snacks
        </div>
        <div style={{    width: "40%"}}>
        <img src="/assets/img/reports/management/page4/585.png" alt="" class="breakfast-img"
            style={{    width: "100%",height : "100%"}}
          />
        </div>
        
       </div>
      </div>

    </div>
      <div class="breakfast-flex mt-5" style={{    padding: "14px",gap : "3%"}}>
        <div class="bordered-container teijsie">
          <div class="labels">
            <img src="/assets/img/reports/management/page4/img.png" alt="image" class="label" />
            <img src="/assets/img/reports/management/page4/img2.png" alt="images" class="label" />
          </div>
          <h1 class="box-text" style={{margin : 0}}>Choose Any One</h1>
          <div class="breakfast-content">
            <div class="breakfast-fullcontent mt-2">
              <div class="management-report-page4-nutrition-mngmnt1-text233">
                1. Curd with Berries and Flax Seeds
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text235">
                Use homemade curd, topped with seasonal berries (like amla or
                jamun) and a sprinkle of flax seeds (provides protein,
                antioxidants, and omega-3 fatty acids).
              </div>
              
              
              <div class="management-report-page4-nutrition-mngmnt1-text238">
                2. Carrot Sticks with Mint Chutney
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text240">
                Fresh carrot sticks served with a side of mint-coriander
                chutney (rich in silica and vitamins).
              </div>
              
              
              <div class="management-report-page4-nutrition-mngmnt1-text243">
                3. Mixed Nuts and Seeds Chaat
              </div>
              
              <div>
                Combine almonds, walnuts, cashews, sunflower seeds, and flax
                seeds (packed with zinc, biotin, and omega-3 fatty acids).
              </div>
              
              
            </div>
          </div>
        </div>

        <div class="bordered-container teijsie">
          <div class="labels">
            <img src="/assets/img/reports/management/page4/img.png" alt="image" class="label" />
          </div>
          <h1 class="box-text" style={{margin : 0}}>Choose Any One</h1>
          <div class="breakfast-content mt-2">
            <div class="breakfast-fullcontent">
              <div class="management-report-page4-nutrition-mngmnt1-text250">
                1.Curd with Seasonal Fruits and Pumpkin Seeds
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text252">
                Homemade curd topped with fruits like pomegranate or papaya
                and a sprinkle of pumpkin seeds (rich in protein,
                antioxidants, and zinc).
              </div>
              
              <div
                class="management-report-page4-nutrition-mngmnt1-text254"
              ></div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text256">
                2. Carrot Sticks with Avocado Dip
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text258">
                Fresh carrot sticks served with mashed avocado and lemon
                (provides silica and healthy fats).
              </div>
              
              <div
                class="management-report-page4-nutrition-mngmnt1-text260"
              ></div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text262">
                3. Mixed Nuts and Dried Fruit Trail Mix
              </div>
              
              <div>
                Combine almonds, raisins, figs, and walnuts (offers zinc,
                biotin, and omega-3 fatty acids).
              </div>
              
            </div>
          </div>
        </div>
      </div>
      </div>
      <div
      className="head-managereport"
      style={{fontSize : "8px",height : "100%",color : "#FFFFFF",background: "rgba(0, 160, 227, 1)"}}
    ><span style={{fontWeight:  "700"}}>Legal Disclaimer</span>: This communication aims to ensure your comprehensive understanding of the diagnosis's nature. (Read more.. Please be cognizant that, as of this juncture, the diagnosis is regarded as provisional, signifying its preliminary status. It is derived solely from the data and photos (if provided) obtained through the online hair test furnished by you. Nevertheless, it is imperative to accentuate that the precision of this diagnosis may exhibit incongruities across individuals. Various determinants, encompassing distinctive health conditions, genetic makeup, and external influences, can contribute to disparities in accuracy. While our utmost endeavor is directed towards accuracy, we hereby acknowledge the potential divergence in individual cases, thus warranting judicious discretion during the interpretation of outcomes.) Diagnosis & Details
    </div>
    </div>

    
    <div className='d-flex flex-column page-break-2' style={{justifyContent : "space-between"}}>
    <div
      className="head-managereport d-flex"
      style={{ justifyContent: "space-between" }}
    >
      <img src="/assets/img/logo.png" className="head-managereport-logo" />
      <div className="head-managereport-text1">Nutrition Management</div>
      <div
        className="text-flex-line-center-veritcal"
        style={{ color: "rgba(84, 84, 84, 1)" }}
      >
        Smart Report
      </div>
    </div>
      <div className='page-break-2'>
     <div style={{display : "flex",justifyContent : "center"}} className='mt-5'>
      <div class="breakfast-titlebox" style={{    width: "40%",    height: "96px"}}>
      <div class="breakfast-flextitle">
        <div class="breakfast-title" style={{    transform: "skew(-20deg)",width:"60%",paddingLeft: "20px",
    fontWeight: "700"}}>
          Dinner
        </div>
        <div style={{    width: "40%"}}>
        <img src="/assets/img/reports/management/page4/57f0d0f4-347f-40f7-ae31-417227dada88.png" alt="" class="breakfast-img"
            style={{    width: "100%",height : "100%"}}
          />
        </div>
        
       </div>
      </div>

    </div>
      <div class="breakfast-flex mt-5" style={{    padding: "14px",gap : "3%"}}>
        <div class="bordered-container teijsie">
          <div class="labels">
            <img src="/assets/img/reports/management/page4/img.png" alt="image" class="label" />
            <img src="/assets/img/reports/management/page4/img2.png" alt="images" class="label" />
          </div>
          <h1 class="box-text" style={{margin : 0}}>Choose Any One</h1>
          <div class="breakfast-content mt-2">
            <div class="breakfast-fullcontent">
              <div class="management-report-page4-nutrition-mngmnt1-text296">
                1. Grilled Fish (Rohu or Pomfret)
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text298">
                Marinated in spices, rich in omega-3 fatty acids.
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text300">
                Serve with steamed broccoli or saag (spinach) for vitamin C
                and iron.
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text302">
                Add a side of roasted sweet potatoes (contains biotin and
                silica).
              </div>
              
              
              <div class="management-report-page4-nutrition-mngmnt1-text305">
                2. Tandoori Chicken or Paneer Tikka
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text307">
                Served with a mixed greens salad (cabbage, carrots, and beets)
                for protein and antioxidants.
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text309">
                Pair with quinoa pulao or a vegetable pulao (rich in protein
                and B-vitamins).
              </div>
              
              
              <div class="management-report-page4-nutrition-mngmnt1-text312">
                3. Chicken or Tofu Curry
              </div>
              
              <div>
                Made with spices, served alongside a side of sautéed asparagus
                or bhindi (okra) for protein and biotin.
              </div>
              
              <div>
                Add a side of roasted sweet potatoes or a small portion of
                bajra roti (source of biotin and silica).
              </div>
              
              
            </div>
          </div>
        </div>

        <div class="bordered-container teijsie">
          <div class="labels">
            <img src="/assets/img/reports/management/page4/img.png" alt="image" class="label" />
          </div>
          <h1 class="box-text" style={{margin : 0}}>Choose Any One</h1>
          <div class="breakfast-content mt-2">
            <div class="breakfast-fullcontent">
              <div class="management-report-page4-nutrition-mngmnt1-text269">
                1.Dal Palak (Lentil Curry with Spinach)
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text271">
                Rich in protein, iron, and vitamin C.
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text273">
                Serve with brown rice or a small portion of jowar roti
                (provides zinc and B-vitamins).
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text275">
                Add a side of steamed broccoli or stir-fried methi (fenugreek)
                for additional nutrients
              </div>
              
              
              <div class="management-report-page4-nutrition-mngmnt1-text278">
                2. Stir-Fried Tofu with Indian Spices
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text280">
                Tofu cooked with broccoli, bell peppers, and mushrooms
                (provides protein and iron).
              </div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text282">
                Serve with a side of vegetable quinoa pulao or khichdi
                (contains protein and zinc).
              </div>
              
              <div
                class="management-report-page4-nutrition-mngmnt1-text284"
              ></div>
              
              <div class="management-report-page4-nutrition-mngmnt1-text286">
                3. Chickpea and Vegetable Curry with Coconut Milk
              </div>
              
              <div>
                Made with chickpeas, carrots, and peas for protein, iron, and
                zinc.
              </div>
              
              <div>Serve with brown rice or whole-wheat chapati.</div>
              
              <div>
                Add a side of sautéed kale or sarson (mustard greens) for iron
                and vitamin C.
              </div>
              
            </div>
          </div>
        </div>
      </div>
      </div>
      <div
      className="head-managereport"
      style={{fontSize : "8px",height : "100%",color : "#FFFFFF",background: "rgba(0, 160, 227, 1)"}}
    ><span style={{fontWeight:  "700"}}>Legal Disclaimer</span>: This communication aims to ensure your comprehensive understanding of the diagnosis's nature. (Read more.. Please be cognizant that, as of this juncture, the diagnosis is regarded as provisional, signifying its preliminary status. It is derived solely from the data and photos (if provided) obtained through the online hair test furnished by you. Nevertheless, it is imperative to accentuate that the precision of this diagnosis may exhibit incongruities across individuals. Various determinants, encompassing distinctive health conditions, genetic makeup, and external influences, can contribute to disparities in accuracy. While our utmost endeavor is directed towards accuracy, we hereby acknowledge the potential divergence in individual cases, thus warranting judicious discretion during the interpretation of outcomes.) Diagnosis & Details
    </div>
    </div>


      
    </div>
    
  );
}
