import React from 'react';
import "./Page7.css"

export default function Page7({data}) {
  console.log("jojerofre",data?.personal)
  return (
    
    <div className='page-7 page-break-1'>
      <div
      className="head-managereport d-flex"
      style={{ justifyContent: "space-between" }}
    >
      <img src="/assets/img/logo.png" className="head-managereport-logo" />
      <div className="head-managereport-text1">Stress Management</div>
      <div
        className="text-flex-line-center-veritcal"
        style={{ color: "rgba(84, 84, 84, 1)" }}
      >
        Smart Report
      </div>
    </div>


  <div>
    <div style={{display : "flex",justifyContent :"center"}}>
      <div style={{width : "65%"}}>
      <h1>Effective Stress Management Tips for Hair Health</h1>
    <div style={{fontSize: "10px"}}>Managing stress is crucial for reducing hair loss, improving blood circulation to hair follicles, and maintaining hormonal balance. It enhances sleep quality, prevents breakage, and promotes better nutrient absorption for healthier hair growth.</div>

      </div>
    </div>

    <div class="content"> 
      <div class="image">
        <img src = "/assets/img/reports/management/page7/image1.png" style={{width : "100%",height : "100%"}} />
      </div>
      <div class="content-body">
        <ul style={{paddingLeft : "13px"}}>
          <li class="main-heading mt-2" style={{fontSize: "8px"}}><strong>1. Practice Mindfulness & Meditation</strong></li>
          <li className='' style={{fontSize: "8px"}}><strong>• Daily Meditation: </strong>Spend 10-15 minutes each day in meditation to calm your mind and reduce anxiety. Regular practice helps lower cortisol levels and promotes overall relaxation.</li>
          <li class="main-content" style={{fontSize: "8px"}}><strong>• Mindful Breathing: </strong>Use simple breathing techniques like 4-7-8 breathing (inhale for 4 seconds, hold for 7, exhale for 8) to quickly reduce stress in moments of tension.</li>
        </ul>
      </div>
    </div>

    <div class="content">
      <div class="content-body-reverse">
        <ul>
        <li class="main-heading mt-2" style={{fontSize: "8px"}}><strong>2. Engage in Regular Physical Activity</strong></li>
        <li className='' style={{fontSize: "8px"}}><strong>• Exercise Daily: </strong>Activities like yoga, brisk walking, or aerobic exercises boost mood, release endorphins, and reduce stress.</li>
        <li class="main-content" style={{fontSize: "8px"}}><strong>• Yoga & Tai Chi: </strong>Incorporate stress-relieving yoga poses like the Child’s Pose or Legs-Up-The-Wall Pose. These gentle postures promote relaxation and better blood flow to the scalp.</li>
        </ul>
      </div>
      <div class="image-reverse">
      <img src = "/assets/img/reports/management/page7/image2.png" style={{width : "100%",height : "100%"}} />
      </div>
    </div>

    <div class="content">
      <div class="image" >
      <img src = "/assets/img/reports/management/page7/image3.png" style={{width : "100%",height : "100%"}} />
      </div>
      <div class="content-body">
        <ul>
          <li class="main-heading mt-2" style={{fontSize: "8px"}}><strong>3. Incorporate Relaxation Techniques</strong></li>
          <li className='' style={{fontSize: "8px"}}><strong>• Progressive Muscle Relaxation (PMR): </strong>Slowly tense and release muscle groups throughout the body to physically release stress.</li>
          <li class="main-content" style={{fontSize: "8px"}}><strong>• Visualization & Guided Imagery: </strong>Close your eyes and imagine peaceful scenery or a calming environment to mentally escape stressful situations.</li>
        </ul>
      </div>
    </div>

    <div class="content">
      <div class="content-body-reverse">
        <ul>
          <li class="main-heading mt-2" style={{fontSize: "8px"}}><strong>4. Maintain a Healthy Sleep Schedule</strong></li>
          <li className='' style={{fontSize: "8px"}}><strong>• Sleep 7-8 Hours: </strong>Adequate sleep is essential for stress management and cellular repair. Create a relaxing bedtime routine by avoiding screens an hour before sleep and incorporating soothing rituals like reading or herbal teas.</li>
        </ul>
      </div>
      <div class="image-reverse">
      <img src = "/assets/img/reports/management/page7/image4.png" style={{width : "100%",height : "100%"}} />
      </div>
    </div>

    <div class="content">
      <div class="image" >
      <img src = "/assets/img/reports/management/page7/image5.png" style={{width : "100%",height : "100%"}} />
      </div>
      <div class="content-body">
        <ul>
          <li class="main-heading mt-2" style={{fontSize: "8px"}}><strong>5. Practice Scalp Massages</strong></li>
          <li className='' style={{fontSize: "8px"}}><strong>• Regular Scalp Massage: </strong>Gently massage your scalp with fingertips or a nourishing oil like lavender or rosemary. This practice not only relaxes the scalp but also promotes hair growth by stimulating blood circulation.</li>
        </ul>
      </div>
    </div>

    <div class="content">
      <div class="content-body-reverse">
        <ul>
          <li class="main-heading mt-2" style={{fontSize: "8px"}}><strong>6. Reduce Environmental Stressors</strong></li>
          <li className='' style={{fontSize: "8px"}}><strong>• Minimize Digital Stress: </strong>Limit exposure to digital screens and social media, which can cause mental fatigue and increase anxiety.</li>
          <li class="main-content" style={{fontSize: "8px"}}><strong>• Create a Calming Space: </strong> Set up a serene environment at home with plants, soft lighting, and calming scents like lavender or chamomile to promote tranquility.</li>
        </ul>
      </div>
      <div class="image-reverse">
      <img src = "/assets/img/reports/management/page7/image6.png" style={{width : "100%",height : "100%"}} />
      </div>
    </div>

    <div className='page-break-2'>
    <div class="content">
      <div class="image" >
      <img src = "/assets/img/reports/management/page7/image7.png" style={{width : "100%",height : "100%"}} />
      </div>
      <div class="content-body">
        <ul>
          <li class="main-heading mt-2" style={{fontSize: "8px"}}><strong>7. Engage in Hobbies & Creative Activities</strong></li>
          <li className='' style={{fontSize: "8px"}}><strong>• Creative Outlets: </strong>Activities like painting, drawing, cooking, or gardening can distract your mind from stress and provide a sense of fulfillment.</li>
          <li class="main-content" style={{fontSize: "8px"}}><strong>• Physical Hobbies: </strong>Explore activities like dancing, hiking, or swimming, which offer physical release and reduce accumulated tension.</li>
        </ul>
      </div>
    </div>

    <div class="content">
      <div class="content-body-reverse">
        <ul>
          <li class="main-heading mt-2" style={{fontSize: "8px"}}><strong>8. Connect with Others</strong></li>
          <li className='' style={{fontSize: "8px"}}><strong>• Talk Therapy: </strong>Speaking with a counselor or joining support groups can be highly effective for managing stress.</li>
          <li class="main-content" style={{fontSize: "8px"}}><strong>• Social Interaction: </strong>Spend time with supportive friends or family, or even engage in volunteer activities to reduce feelings of isolation and anxiety.</li>
        </ul>
      </div>
      <div class="image-reverse">
      <img src = "/assets/img/reports/management/page7/image8.png" style={{width : "100%",height : "100%"}} />
      </div>
    </div>
    </div>

    <div class="disclamer" >
      <strong>Integrate these stress management practices into your routine to promote healthier hair and overall well-being.</strong>
    </div>

    <footer
      className="head-managereport"
      style={{fontSize : "8px",height : "100%",color : "#FFFFFF",background: "rgba(0, 160, 227, 1)",marginTop : "2%"}}
    ><span style={{fontWeight:  "700"}}>Legal Disclaimer</span>: This communication aims to ensure your comprehensive understanding of the diagnosis's nature. (Read more.. Please be cognizant that, as of this juncture, the diagnosis is regarded as provisional, signifying its preliminary status. It is derived solely from the data and photos (if provided) obtained through the online hair test furnished by you. Nevertheless, it is imperative to accentuate that the precision of this diagnosis may exhibit incongruities across individuals. Various determinants, encompassing distinctive health conditions, genetic makeup, and external influences, can contribute to disparities in accuracy. While our utmost endeavor is directed towards accuracy, we hereby acknowledge the potential divergence in individual cases, thus warranting judicious discretion during the interpretation of outcomes.) Diagnosis & Details
    </footer>
  </div>
    </div>
  );
}
